import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import LandingLayout from '../layouts/landingLayout';
import HomePage from '../pages/landing/home';
import LoginPage from '../pages/auth/login';
// import RequestLoginPage from '../pages/auth/RequestLogin';
import ForgotPassword from '../pages/auth/forgotPassword';
import ResetPassword from '../pages/auth/resetPassword';
import ResourcesPage from '../pages/landing/resources';
import ResourceDetailPage from '../pages/landing/resource-detail';
import NewsPage from '../pages/landing/news';
import NewsDetailPage from '../pages/landing/newsDetail';
import MinistryDetailPage from '../pages/landing/ministryDetail';
import TestimonialDetailPage from '../pages/landing/testimonialDetail';
import { CustomContentPage } from '../pages/landing/customContentPage';
import AppLayout from '../layouts/appLayout';
import DashboardPage from '../pages/app/dashboard';
import AdminProfilePage from '../pages/app/profileAdmin';
import UserProfilePage from '../pages/app/profileUser';
import UsersPage from '../pages/app/users';
import UserNewPage from '../pages/app/userNew';
import UserEditPage from '../pages/app/userEdit';
import RequestPage from '../pages/app/requests';
import RequestNewPage from '../pages/app/requestNew';
import RequestEditPage from '../pages/app/requestEdit';
import ResourcePage from '../pages/app/resources';
import ResourceInventories from '../pages/app/resourceInventories';
import ReportsPage from '../pages/app/reports';
import CategoryPage from '../pages/app/categories';
import AdminUsersPage from '../pages/app/adminUser';
import AdminUserNewPage from '../pages/app/adminUserNew';
import AdminUserEditPage from '../pages/app/adminUserEdit';
import RolesPage from '../pages/app/roles';
import RoleNewPage from '../pages/app/roleNew';
import RoleEditPage from '../pages/app/roleEdit';
import PagesPage from '../pages/app/pages';
import PageAddPage from '../pages/app/pageAdd';
import PageEditPage from '../pages/app/pageEdit';
import FormsPage from '../pages/app/forms';
import FormNewPage from '../pages/app/formNew';
import FormDetailPage from '../pages/app/formDetail';
import FormSubmissionsPage from '../pages/app/formSubmissions';
import FormSubmissionDetailPage from '../pages/app/formSubmissionDetail';
import PostsPage from '../pages/app/posts';
import PostCreatePage from '../pages/app/postsCreate';
import PostEditPage from '../pages/app/postsEdit';
import MinistriesPage from '../pages/app/ministries';
import MinistryCreatePage from '../pages/app/ministryCreate';
import MinistryEditPage from '../pages/app/ministryEdit';
import TestimonialsPage from '../pages/app/testimonials';
import TestimonialNewPage from '../pages/app/testimonialNew';
import TestimonialEditPage from '../pages/app/testimonialEdit';
import ExclusionsPage from '../pages/app/exclusionsPage';
import ExclusionCreatePage from '../pages/app/exclusionCreate';
import ExclusionEditPage from '../pages/app/exclusionEdit';

const AppRoutes = () => {
  const { me } = useAuth();

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <LandingLayout />,
      children: [
        {
          path: '/',
          element: <HomePage />
        },
        {
          path: '/login',
          element: <LoginPage />
        },
        // {
        //   path: '/request-login',
        //   element: <RequestLoginPage />
        // },
        // {
        //   path: '/request-login/:token',
        //   element: <RequestLoginPage />
        // },
        {
          path: '/forgot-password',
          element: <ForgotPassword />
        },
        {
          path: '/reset-password/:token',
          element: <ResetPassword />
        },
        {
          path: '/resources',
          element: <ResourcesPage />
        },
        {
          path: '/resources/:id',
          element: <ResourceDetailPage />
        },
        {
          path: '/news/:ministryId',
          element: <NewsPage />
        },
        {
          path: '/news/:ministryId/:newsUrl',
          element: <NewsDetailPage />
        },
        {
          path: '/ministries/:ministryUrl',
          element: <MinistryDetailPage />
        },
        {
          path: '/testimonials/:testimonialUrl',
          element: <TestimonialDetailPage />
        },
        {
          path: '/:id',
          element: <CustomContentPage />
        },
      ]
    },
    {
      path: '/app',
      element: <AppLayout />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardPage />
        },
        {
          path: 'profile',
          element: me?.isSuperuser ? <AdminProfilePage /> : <UserProfilePage />
        },
        {
          path: 'application',
          element: <UsersPage />
        },
        {
          path: 'application/new',
          element: <UserNewPage />
        },
        {
          path: 'application/:userId',
          element: <UserEditPage />
        },
        {
          path: 'request',
          element: <RequestPage />
        },
        {
          path: 'request/new',
          element: <RequestNewPage />
        },
        {
          path: 'request/:requestId',
          element: <RequestEditPage />
        },
        {
          path: 'resource',
          element: <ResourcePage />
        },
        {
          path: 'resources/:id/inventories',
          element: <ResourceInventories />
        },
        {
          path: 'exclusions',
          element: <ExclusionsPage />
        },
        {
          path: 'exclusions/new',
          element: <ExclusionCreatePage />
        },
        {
          path: 'exclusions/:exclusionId',
          element: <ExclusionEditPage />
        },
        {
          path: 'reports',
          element: <ReportsPage />
        },
        {
          path: 'categories',
          element: <CategoryPage />
        },
        {
          path: 'admin',
          element: <AdminUsersPage />
        },
        {
          path: 'admin/new',
          element: <AdminUserNewPage />
        },
        {
          path: 'admin/:userId',
          element: <AdminUserEditPage />
        },
        {
          path: 'roles',
          element: <RolesPage />
        },
        {
          path: 'roles/new',
          element: <RoleNewPage />
        },
        {
          path: 'roles/:roleId',
          element: <RoleEditPage />
        },
        {
          path: 'pages',
          element: <PagesPage />
        },
        {
          path: 'pages/new',
          element: <PageAddPage />
        },
        {
          path: 'pages/:pageId',
          element: <PageEditPage />
        },
        {
          path: 'forms',
          element: <FormsPage />
        },
        {
          path: 'forms/new',
          element: <FormNewPage />
        },
        {
          path: 'forms/:formId',
          element: <FormDetailPage />
        },
        {
          path: 'forms/:formId/submissions',
          element: <FormSubmissionsPage />
        },
        {
          path: 'forms/:formId/submissions/:submissionId',
          element: <FormSubmissionDetailPage />
        },
        {
          path: 'posts/:ministryId',
          element: <PostsPage />
        },
        {
          path: 'posts/:ministryId/create',
          element: <PostCreatePage />
        },
        {
          path: 'posts/:ministryId/:postId',
          element: <PostEditPage />
        },
        {
          path: 'ministry',
          element: <MinistriesPage />
        },
        {
          path: 'ministry/new',
          element: <MinistryCreatePage />
        },
        {
          path: 'ministry/:ministryId',
          element: <MinistryEditPage />
        },
        {
          path: 'testimonials',
          element: <TestimonialsPage />
        },
        {
          path: 'testimonials/new',
          element: <TestimonialNewPage />
        },
        {
          path: 'testimonials/:testimonialId',
          element: <TestimonialEditPage />
        },
        { path: '*', element: <Navigate to='/app/application' replace /> },
      ]
    },
    { path: '*', element: <div>Not found</div> }
  ];

  return useRoutes(routes);
};

export default AppRoutes;
