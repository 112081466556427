import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Stack, Table, TableBody, TableContainer, TableHead, TableSortLabel, Typography } from '@mui/material';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormDateTimeField from '../../libs/ui/FormDateTimeField';
import { StyledTableCell as TableCell, StyledTableRow } from '../../libs/ui/StyledComponents';
import { ReportSortField, SortDirectionField, useResourcesInventoryLazyQuery } from '../../graphql/generated/graphql';

const currentDate = new Date();
const oneYearAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));

const RemainingInventoryReports = () => {
  const [start, setStart] = useState<Date>(oneYearAgo);
  const [end, setEnd] = useState<Date>(new Date());
  const [orderBy, setOrderBy] = useState<ReportSortField>(ReportSortField.Title);
  const [orderDirection, setOrderDirection] = useState<SortDirectionField>(SortDirectionField.Asc);
  const [monthSelected, setMonthSelected] = useState(1)
  const validationSchema = Yup.object().shape({
    fromDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  const methods = useForm({
    defaultValues: {
      fromDate: oneYearAgo,
      endDate: new Date(),
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const [getData, { data }] = useResourcesInventoryLazyQuery({ fetchPolicy: 'network-only' });

   useEffect(() => {
    getData({ variables: { start, end, sort: orderBy, sortDirection: orderDirection } });
  }, [getData, start, end, orderBy, orderDirection]);


  const summary = useMemo(() => {
    if (data?.ResourcesInventory?.items) {
      return data.ResourcesInventory.items.filter(item => item.sku && item.title);
    }
    return [];
  }, [data]);

  useEffect(()=> {
    const totalDaysSelected = (new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60 * 60 * 24);
    let totalMonthsSelected = totalDaysSelected >= 30 ? totalDaysSelected / 30 : 1;
  
    const decimalPart = totalMonthsSelected % 1;
    totalMonthsSelected = decimalPart >= 0.5
      ? Math.ceil(totalMonthsSelected)
      : Math.floor(totalMonthsSelected);
    setMonthSelected(totalMonthsSelected)
  }, [start,end])


  const onSubmit = (values: FieldValue<any>) => {
    setStart(values.fromDate);
    setEnd(values.endDate);
  };

  const handleRequestSort = (column: ReportSortField) => {
    const isAsc = orderBy === column && orderDirection === SortDirectionField.Asc;
    const newDirection = isAsc ? SortDirectionField.Desc : SortDirectionField.Asc;
    
    setOrderBy(column);
    setOrderDirection(newDirection);
    
  };


  return (
    <Box mt={1}>
      <Box border='solid 1px lightgray' mt={2} px={1} py={2}>
        <Stack width='fit-content' bgcolor='#fff' mt={-2.5} px={1} direction='column' justifyContent='center' spacing={1}>
          <Typography variant='body1' fontWeight={600}>Months of Remaining Inventory</Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name='fromDate'
              label='Date From'
              labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Date From</Typography>}
              size='small'
              type='datetime-local'
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name='endDate'
              label='Date To'
              labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Date To</Typography>}
              size='small'
              type='date'
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1, display: 'flex', flexDirection: 'row' } }}
              control={control}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} display='flex' justifyContent='flex-start'>
        <Button variant='outlined' onClick={handleSubmit(onSubmit)}>Submit</Button>
      </Box>
      <TableContainer sx={{ mt: 1 }}>
        <Table aria-labelledby='tableTitle' size='small'>
          <TableHead>
            <StyledTableRow>
              <TableCell width={150}>
                <TableSortLabel
                  active={orderBy === ReportSortField.Sku}
                  direction={orderBy === ReportSortField.Sku ? orderDirection.toLowerCase() as "asc" | "desc" : "asc"}
                  onClick={() => handleRequestSort(ReportSortField.Sku)}
                >
                  SKU
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === ReportSortField.Title}
                  direction={orderBy === ReportSortField.Title ? orderDirection.toLowerCase() as "asc" | "desc" : "asc"}
                  onClick={() => handleRequestSort(ReportSortField.Title)}
                >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell width={150} sx={{ paddingLeft: '12px!important' }}>Current Inventory</TableCell>
              <TableCell width={150} sx={{ paddingLeft: '12px!important' }}>Monthly Average for Selected Period</TableCell>
              <TableCell width={150} sx={{ paddingLeft: '12px!important' }}>Projected Months of Inventory per SKU</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {summary.map(row => (
              <StyledTableRow hover role='checkbox' tabIndex={-1}>
                <TableCell align='left'>{row.sku}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.title}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.inventory}</TableCell>
                <TableCell align='right' sx={{ paddingRight: '12px!important' }}> {(row.lastYearShipped! / monthSelected).toFixed(1)}</TableCell>
                <TableCell align='right' sx={{ paddingRight: '12px!important' }}>
                  {row.lastYearShipped === 0 ? 0 : (row.inventory * monthSelected / row.lastYearShipped!).toFixed(1)}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default RemainingInventoryReports;
