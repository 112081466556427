import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import apolloClient from '../../graphql/apolloClient';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../contexts/auth';
import { ExclusionGroupTableHead } from './ExclusionGroupTableHead';
import { CustomTableToolbar } from '../../libs/ui/TableToolbar';
import { DeleteModal } from '../../libs/ui/DeleteModal';
import CircularLoading from '../../libs/ui/Loading';
import { showSuccess } from '../../libs/utils/toasts';
import { getExclusionGroupInput } from './util';
import {
  CreateExclusionGroupDocument,
  DeleteExclusionGroupDocument,
  ExclusionGroupSortField,
  SortDirectionField,
  useExclusionGroupQuery,
  ExclusionGroupInput,
  IExclusionGroup,
  ExclusionGroupsDocument,
  ExclusionGroupByIdDocument,
} from '../../graphql/generated/graphql';
import { exclusionGroupHeadCells } from './const';
import { Order } from '../../const/type';
import { useNavigate } from 'react-router-dom';

export const ExclusionContainer = () => {
  const { me } = useAuth();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<IExclusionGroup[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [orderBy, setOrderBy] = useState<keyof IExclusionGroup>('id');
  const [order, setOrder] = useState<Order>('asc');
  //const [tempExclusionGroupInput, setTempExclusionGroupInput] = useState<ExclusionGroupInput>(initTempExclusionGroupInput);
  //const [openFormModal, setOpenFormModal] = useState(false);
  //const [onEdit, setOnEdit] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);

  const [createExclusionGroupQuery] = useMutation(CreateExclusionGroupDocument, {
    refetchQueries: [
      {
        query: ExclusionGroupsDocument,
        variables: { resourcesPage: page + 1, resourcesPageSize: rowsPerPage },
        fetchPolicy: 'network-only',
      },
    ],
  });

  // const [updateExclusionGroupMutation] = useMutation(UpdateExclusionGroupDocument, {
  //   refetchQueries: [
  //     {
  //       query: ExclusionGroupsDocument,
  //       variables: { resourcesPage: page + 1, resourcesPageSize: rowsPerPage },
  //       fetchPolicy: 'network-only',
  //     },
  //   ],
  // });

  const [deleteExclusionGroupMutation] = useMutation(DeleteExclusionGroupDocument, {
    refetchQueries: [
      {
        query: ExclusionGroupsDocument,
        variables: { page: page + 1, pageSize: rowsPerPage },
        fetchPolicy: 'network-only',
      },
    ]
  });

  // const createExclusionGroup = async (inputData: ExclusionGroupInput) => {
  //   const { data } = await createExclusionGroupQuery({ variables: { exclusionGroup: inputData } });
  //   if (data?.CreateExclusionGroup?.data) {
  //     const _rows = rows;
  //     _rows.push(data.CreateExclusionGroup.data);
  //     setRows([..._rows]);
  //     setTotalCount(totalCount + 1);
  //   }
  //   setTempExclusionGroupInput(initTempExclusionGroupInput);
  // };

  const duplicateExclusionGroup = async (inputData: ExclusionGroupInput) => {
    const tempInput = { ...inputData, name: `${inputData.name} - Copy` }
    const { data: exclusionGroupData } = await apolloClient.query({
      query: ExclusionGroupByIdDocument,
      variables: { exclusionGroupId: Number(tempInput.id) },
      fetchPolicy: 'network-only',
    });
    tempInput.resourceIds = exclusionGroupData?.ExclusionGroup?.data?.resources ? exclusionGroupData.ExclusionGroup.data.resources.map((resource: any) => resource.id) : []
    delete tempInput.id
    const { data } = await createExclusionGroupQuery({ variables: { exclusionGroup: tempInput } });
    if (data?.CreateExclusionGroup?.data) {
      const _rows = rows;
      _rows.push(data.CreateExclusionGroup.data);
      setRows([..._rows]);
      setTotalCount(totalCount + 1);
    }
    //setTempExclusionGroupInput(initTempExclusionGroupInput);
  };

  // const updateExclusionGroup = async (inputData: ExclusionGroupInput | ExclusionGroupUpdateInput) => {
  //   const _postData = findDifferentValues(tempExclusionGroupInput, inputData);

  //   const { data } = await updateExclusionGroupMutation({ variables: { exclusionGroup: _postData } });
  //   if (data?.UpdateExclusionGroup?.data) {
  //     let _rows = rows;
  //     const existIndex = _rows.findIndex((row) => row.id === data.UpdateExclusionGroup.data?.id);
  //     if (existIndex >= 0) {
  //       const newRows = _rows.map((row, index) => index === existIndex ? data.UpdateExclusionGroup.data : row)
  //       setRows([...newRows]);
  //     }
  //   }
  //   setTempExclusionGroupInput(tempExclusionGroupInput);
  // };

  const { data, loading } = useExclusionGroupQuery({
    variables: {
      page: page + 1,
      pageSize: rowsPerPage,
      search: '',
      sort: 'name' as ExclusionGroupSortField,
      sortDirection: order === 'asc' ? SortDirectionField.Asc : SortDirectionField.Desc
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.ExclusionGroups?.items) {
      // @ts-ignore
      setRows(_rows => {
        data.ExclusionGroups.items.forEach((item: any) => {
          const _exist = _rows.find((row: IExclusionGroup) => row.id === item.id);
          if (!_exist) _rows.push(item);
          if (_exist && _exist !== item) _rows[_rows.indexOf(_exist)] = item;
        });
        return [..._rows];
      });
    }
    if (data?.ExclusionGroups) setTotalCount(data.ExclusionGroups.total);
  }, [data, setRows, setTotalCount]);

  const handleRequestSort = (property: keyof IExclusionGroup) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleSave = async (data: ExclusionGroupInput) => {
  //   if (onEdit) {
  //     await updateExclusionGroup(data);
  //   } else {
  //     await createExclusionGroup(data);
  //   }
  //   setOnEdit(false);
  //   setOpenFormModal(false);
  //   setTempExclusionGroupInput(tempExclusionGroupInput);
  // };

  const handleDelete = async () => {
    const { data } = await deleteExclusionGroupMutation({
      variables: { exclusionGroupId: deleteId }
    });

    if (data?.DeleteExclusionGroup?.status) {
      // @ts-ignore
      setRows(_rows => {
        return _rows.filter((item: IExclusionGroup) => item.id !== deleteId)
      })
      setDeleteId(undefined);
      setDeleteModalVisible(false);
    }
  }

  const onAddExclusionGroup = () => {
    if (!me?.role?.editResource) return showSuccess('Your role doesn\'t have the permission');
    navigate(`/app/exclusions/new`)
  }

  const onDuplicateExclusionGroup = (row: IExclusionGroup) => {
    if (!me?.role?.editResource) return showSuccess('Your role doesn\'t have the permission');
    duplicateExclusionGroup(getExclusionGroupInput(row))
  }

  const onEditResource = (row: IExclusionGroup) => {
    if (!me?.role?.editResource) return showSuccess('Your role doesn\'t have the permission');
    navigate(`/app/exclusions/${row.id}`)
  }

  const onDeleteResource = (row: IExclusionGroup) => {
    if (!me?.role?.editResource) return showSuccess('Your role doesn\'t have the permission');
    setDeleteId(row.id);
    setDeleteModalVisible(true)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, rowsPerPage, rows]);

  const ToolbarActions = (
    <Button variant='contained' size='small' onClick={onAddExclusionGroup}>Add Exclusion Group</Button>
  );

  if (loading) return <CircularLoading />;

  return (
    <Box width='100%'>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <CustomTableToolbar
          title='Exclusion Groups'
          numSelected={0}
          //onAdd={() => setOpenFormModal(true)}
          disableAction={!me?.isSuperuser && !me?.role?.changeUsersProfile}
          customActionButton
          actions={ToolbarActions}
          showActions
        />
        <TableContainer sx={{ minHeight: 'calc(100vh - 410px)' }}>
          <Table size='small' sx={{ minWidth: 750, minHeight: visibleRows.length === 0 ? 300 : 0 }}>
            <ExclusionGroupTableHead
              headCells={exclusionGroupHeadCells}
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={visibleRows.length < rowsPerPage ? visibleRows.length : rowsPerPage}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    onClick={(event: React.MouseEvent<unknown, MouseEvent>) => handleClick(event, row.id)}
                    role='checkbox'
                    tabIndex={-1}
                    key={row.id.toString()}
                    sx={{ cursor: 'pointer' }}
                  >
                    {(me?.isSuperuser || me?.role?.editResource) &&
                      <TableCell width={200} align='center'>
                        <Box>
                          <IconButton onClick={() => onEditResource(row)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => onDeleteResource(row)}>
                            <DeleteIcon />
                          </IconButton>
                          <IconButton onClick={() => onDuplicateExclusionGroup(row)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    }
                    <TableCell align='left'>{row.name}</TableCell>
                    <TableCell align='left'>{row.description}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <DeleteModal
        title='Are you sure you want to delete this exclusion group?'
        open={deleteModalVisible}
        onCancel={() => { setDeleteModalVisible(false); setDeleteId(undefined) }}
        onConfirm={handleDelete}
      />

      {/* <Modal open={openFormModal} onClose={() => { setOpenFormModal(false); setTempExclusionGroupInput(tempExclusionGroupInput); setOnEdit(false) }}>
        <Box>
          <ExclusionGroupForm
            onEdit={onEdit}
            value={tempExclusionGroupInput}
            exclusionGroup={onEdit ? tempExclusionGroup : { id: -1,
              isActive: true,
              isDeleted: false,
              endDateDays: 365,
              name: '',
              description: '',
              resources: [] 
            }}
            setValue={setTempExclusionGroupInput}
            onCancel={() => { setOnEdit(false); setTempExclusionGroupInput(initTempExclusionGroupInput); setOpenFormModal(false) }}
            onSave={handleSave}
          />
        </Box>
      </Modal> */}
    </Box>
  );
}
