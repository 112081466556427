import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
  TableHead,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import FormDateTimeField from "../../libs/ui/FormDateTimeField";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRequestShipReportLazyQuery } from "../../graphql/generated/graphql";
import { useEffect, useMemo, useState } from "react";
import {
  StyledTableCell as TableCell,
  StyledTableRow,
} from "../../libs/ui/StyledComponents";

const currentDate = new Date(new Date(new Date().setHours(0)).setMinutes(0));
const beforeDate = new Date(new Date(new Date().setHours(0)).setMinutes(0));

const ShipmentReports = () => {

  const [showData, setShowData] = useState<boolean>(false);
  const validationSchema = Yup.object().shape({
    fromDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
  });

  const [requestChartType, setRequestChartType] = useState<string>("month");
  const [shipChartType, setShipChartType] = useState<string>("month");

  const methods = useForm({
    defaultValues: {
      fromDate: new Date(beforeDate.setMonth(beforeDate.getMonth() - 1)),
      endDate: currentDate,
    },
    resolver: yupResolver(validationSchema),
  });

  const [getData, { data }] = useRequestShipReportLazyQuery();

  const { handleSubmit, control, watch } = methods;

  const onSubmit = async (_value: any) => {
    setShowData(false);
    await getData({
      variables: {
        start: _value.fromDate,
        end: _value.endDate,
      },
    });

    setShowData(true);
  }

  const reportData = useMemo(() => {
    if (data?.RequestShipReport.data) return JSON.parse(data.RequestShipReport.data);
    return null;
  }, [data]);

  const requestChartData = useMemo(() => {
    if (reportData) {
      return reportData?.requestChart[requestChartType];
    }
    return [];
  }, [reportData, requestChartType]);

  const shipChartData = useMemo(() => {
    if (reportData) {
      return reportData?.resourceChart[shipChartType];
    }
    return [];
  }, [reportData, shipChartType]);

  const requestReportData = useMemo(() => {
    if (requestChartData.length) {
      return requestChartData.map((_item: any) => Number(_item.count));
    }
    return [];
  }, [requestChartData]);

  const shipReportData = useMemo(() => {
    if (shipChartData.length) {
      return shipChartData.map((_item: any) => Number(_item.count));
    }
    return [];
  }, [shipChartData]);

  const requestChartLabels = useMemo(() => {
    if (requestChartData.length) {
      return requestChartData.map((_item: any) => _item[requestChartType]);
    }
    return [];
  }, [requestChartData, requestChartType]);

  const shipChartLabels = useMemo(() => {
    if (shipChartData.length) {
      return shipChartData.map((_item: any) => _item[shipChartType]);
    }
    return [];
  }, [shipChartData, shipChartType]);

  useEffect(() => {
    if (reportData) {
      setShowData(true);
    } else setShowData(false);
  }, [reportData]);

  const handleRequestChartChange = (e: any) => {
    setRequestChartType(e.target.value);
  };

  const handleShipChartChange = (e: any) => {
    setShipChartType(e.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          border: "solid 1px lightgray",
          padding: 1,
          paddingBottom: 2,
          marginTop: 2,
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          sx={{
            mt: "-20px",
            background: "white",
            width: "fit-content",
            padding: "0px 8px",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "600" }}>
            Request Filter
          </Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name="fromDate"
              label={"Date From"}
              size="small"
              type="datetime-local"
              maxDateTime={watch("endDate") ?? currentDate}
              labelComponent={
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: "400",
                    minWidth: "130px",
                    lineHeight: "40px",
                  }}
                >
                  {"Date From"}
                </Typography>
              }
              control={control}
              rows={1}
              inputProps={{ sx: { backgroundColor: "white", height: 40 } }}
              containerProps={{
                sx: { display: "flex", flexDirection: "row", mt: 1 },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} pr={3} pl={1}>
            <FormDateTimeField
              name="endDate"
              label={"Date To"}
              size="small"
              type="date"
              minDateTime={watch("fromDate") ?? currentDate}
              maxDateTime={currentDate}
              labelComponent={
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: "400",
                    minWidth: "130px",
                    lineHeight: "40px",
                  }}
                >
                  {"Date To"}
                </Typography>
              }
              control={control}
              rows={1}
              inputProps={{ sx: { backgroundColor: "white", height: 40 } }}
              containerProps={{
                sx: { display: "flex", flexDirection: "row", mt: 1 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-start" }}>
        <Button variant="outlined" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </Box>
      {showData && (
        <Box mt={4}>
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            Shipment Results
          </Typography>
          <Box mt={1}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Shipped Requests
            </Typography>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={"small"}>
                <TableHead>
                  <StyledTableRow>
                    <TableCell>Title</TableCell>
                    <TableCell sx={{ paddingLeft: "12px!important" }}>
                      Count
                    </TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell align="left">{"Total"}</TableCell>
                    <TableCell
                      align="left"
                      sx={{ paddingLeft: "12px!important" }}
                    >
                      {reportData?.total}
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ marginTop: "12px", borderWidth: "1px" }} />
          </Box>
          <Box mt={1}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Shipped Resources
            </Typography>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={"small"}>
                <TableHead>
                  <StyledTableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell sx={{ paddingLeft: "12px!important" }}>
                      Amount of Shipped Resource
                    </TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {[ ...reportData?.resource ]?.sort((a: any, b: any) => (a.title?.toLowerCase() > b.title?.toLowerCase()) ? 1 : ((b.title?.toLowerCase() > a.title?.toLowerCase()) ? -1 : 0)).map((row: any) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`status-${row.sku}`}
                    >
                      <TableCell align="left">{row.sku}</TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ paddingLeft: "12px!important" }}
                      >
                        {row.count}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ marginTop: "12px", borderWidth: "1px" }} />
          </Box>
          <Box mt={1}>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Shipments Per State
            </Typography>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={"small"}>
                <TableHead>
                  <StyledTableRow>
                    <TableCell>State</TableCell>
                    <TableCell>Request Count</TableCell>
                    <TableCell sx={{ paddingLeft: "12px!important" }}>
                      Book Resource Count
                    </TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {reportData.state.map((row: any) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`status-${row.state}`}
                    >
                      <TableCell align="left">{row.state}</TableCell>
                      <TableCell align="left">{row.requestCount}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ paddingLeft: "12px!important" }}
                      >
                        {row.resourceCount}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ marginTop: "12px", borderWidth: "1px" }} />
          </Box>
          {requestReportData && requestReportData.length > 0 && (
            <Box mt={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={requestChartType}
                  onChange={handleRequestChartChange}
                >
                  <FormControlLabel
                    value="year"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Years</Typography>
                    }
                    sx={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    value="month"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Months</Typography>
                    }
                    sx={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    value="week"
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Weeks</Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    sx={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    value="day"
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Days</Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    sx={{ marginBottom: 0 }}
                  />
                </RadioGroup>
              </FormControl>
              <LineChart
                height={300}
                series={[{ data: requestReportData, label: 'Shipped Requests' }]}
                xAxis={[{ scaleType: "point", data: requestChartLabels }]}
                sx={{
                  ".MuiLineElement-root, .MuiMarkElement-root": {
                    strokeWidth: 1,
                  },
                  ".MuiLineElement-series-pvId": {
                    strokeDasharray: "5 5",
                  },
                  ".MuiLineElement-series-uvId": {
                    strokeDasharray: "3 4 5 2",
                  },
                  ".MuiMarkElement-root:not(.MuiMarkElement-highlighted)": {
                    fill: "#fff",
                  },
                  "& .MuiMarkElement-highlighted": {
                    stroke: "none",
                  },
                }}
              />
            </Box>
          )}
          {shipReportData && shipReportData.length > 0 && (
            <Box mt={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={shipChartType}
                  onChange={handleShipChartChange}
                >
                  <FormControlLabel
                    value="year"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Years</Typography>
                    }
                    sx={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    value="month"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Months</Typography>
                    }
                    sx={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    value="week"
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Weeks</Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    sx={{ marginBottom: 0 }}
                  />
                  <FormControlLabel
                    value="day"
                    label={
                      <Typography sx={{ fontSize: "16px", marginBottom: 0 }}>Days</Typography>
                    }
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "16px",
                          },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    sx={{ marginBottom: 0 }}
                  />
                </RadioGroup>
              </FormControl>
              <LineChart
                height={300}
                series={[{ data: shipReportData, label: 'Shipped Resources' }]}
                xAxis={[{ scaleType: "point", data: shipChartLabels }]}
                sx={{
                  ".MuiLineElement-root, .MuiMarkElement-root": {
                    strokeWidth: 1,
                  },
                  ".MuiLineElement-series-pvId": {
                    strokeDasharray: "5 5",
                  },
                  ".MuiLineElement-series-uvId": {
                    strokeDasharray: "3 4 5 2",
                  },
                  ".MuiMarkElement-root:not(.MuiMarkElement-highlighted)": {
                    fill: "#fff",
                  },
                  "& .MuiMarkElement-highlighted": {
                    stroke: "none",
                  },
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ShipmentReports;
