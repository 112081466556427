import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { ExclusionGroupEditForm } from '../../components/resources/ExclusionMutateContainer';

const ExclusionCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { me } = useAuth();

  useEffect(() => {
    if (!me) return;
    if (!me?.role?.viewResource) navigate('/');
  }, [me, me?.role?.viewResource, navigate])

  return <ExclusionGroupEditForm />;
};

export default ExclusionCreatePage;