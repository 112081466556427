import React from 'react';
import { Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

interface CustomTableToolbarProps {
  title: string;
  numSelected: number;
  onAdd?: () => void;
  onDelete?: () => void;
  disableAction?: boolean;
  actions?: React.ReactElement;
  showActions?: boolean;
  searchBar?: React.ReactElement;
  searchKey?: string;
  customActionButton?: boolean;
  searchId?: React.ReactElement;
}

export function CustomTableToolbar(props: CustomTableToolbarProps) {
  const {
    numSelected,
    actions,
    showActions,
    searchBar,
    searchKey,
    customActionButton = false,
    searchId,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component='div' variant='subtitle1' color='inherit' sx={{ flex: 1 }}>{numSelected} selected</Typography>
      ) : (
        <>
          {searchKey && searchKey.length > 0 ? (
            <Typography id='tableTitle' component='div' variant='h6' sx={{ flex: 1 }}>{props.title} - {`Search: ${searchKey}`}</Typography>
          ) : (
            <Typography id='tableTitle' component='div' variant='h6' sx={{ flex: 1 }}>{props.title}</Typography>
          )}
        </>
      )}
      {!props.disableAction && (
        <>
          {(numSelected > 0 || showActions) && (
            <>
              {!customActionButton && props.onDelete && (
                <Tooltip title='Delete' onClick={props.onDelete}>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              {actions}
            </>
          )}
          {!customActionButton && <Button variant='contained' size='small' onClick={props.onAdd}>Add</Button>}
        </>
      )}
      {searchId ?? searchId}
      {searchBar ?? searchBar}
    </Toolbar>
  );
}
