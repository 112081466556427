import React from 'react';
import {
  Box,
  BoxProps,
  Chip,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
  Checkbox,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { Control, Controller } from 'react-hook-form';

export interface DropDownItem {
  label: string;
  value: any;
  disabled?: boolean;
}

const getStyles = (name: DropDownItem, personName: readonly DropDownItem[], theme: Theme) => {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

export interface FormDropdownProps extends SelectProps {
  name: string;
  label: string;
  labelComponent?: React.ReactElement;
  placeholder?: string;
  options: DropDownItem[];
  onSelect?: (value: any) => void;
  maxSelectLimit?: number;
  containerProps?: BoxProps;
  control: Control<any>;
}

const FormDropdownField = ({
  name,
  label,
  labelComponent,
  placeholder = undefined,
  options = [],
  onSelect,
  size = 'medium',
  maxSelectLimit = 0,
  multiple = false,
  containerProps,
  control,
  ...props
}: FormDropdownProps) => {
  const theme = useTheme();
  const getLabel = (value: any) => {
    if (!value || value === '' || value === ' ') return '';
    const _item = options.find((_option) => _option.value === value);
    return _item?.label ?? '';
  };
  if (multiple) {
    return (
      <Box sx={containerProps?.sx}>
        {labelComponent}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Stack width='100%'>
                <Select
                  labelId={`select-dropdown-${name}-label`}
                  id={`select-dropdown-${name}`}
                  value={value}
                  fullWidth
                  multiple
                  size={size}
                  placeholder={placeholder}
                  label={labelComponent ? '' : label}
                  renderValue={(selected) => (
                    <Box display='flex' flexWrap='nowrap' gap={0.5}>
                      {[ ...selected ].sort(
                        (a: any, b: any) => {
                          return (getLabel(a)?.toLowerCase() > getLabel(b)?.toLowerCase()) ? 1 : ((getLabel(b)?.toLowerCase() > getLabel(a)?.toLowerCase()) ? -1 : 0)
                      }).map((value: any) => <Chip key={value} label={getLabel(value)} />)}
                    </Box>
                  )}
                  onChange={(e) => {
                    onChange(e);
                    if (onSelect) onSelect(e);
                  }}
                  {...props}
                >
                  {options.map((option) => {
                    if (!maxSelectLimit || value.length < maxSelectLimit) {
                      return (
                        <MenuItem key={`${option.value}-${option.label}`} value={option.value} style={getStyles(option, options, theme)} disabled={option?.disabled} >
                          <Checkbox checked={value.indexOf(option.value) > -1} /> {option.label}
                        </MenuItem>
                      );
                    }
                    if (value.length === maxSelectLimit && value.includes(option.value)) {
                      return (
                        <MenuItem key={`${option.value}-${option.label}`} value={option.value} style={getStyles(option, options, theme)} disabled={option?.disabled} >
                          <Checkbox checked={value.indexOf(option.value) > -1} /> {option.label}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem key={`${option.value}-${option.label}`} value={option.value} style={getStyles(option, options, theme)} disabled>
                          <Checkbox checked={value.indexOf(option.value) > -1} /> {option.label}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                {error && <Typography margin='4px 14px 0' fontSize={14} color='#d32f2f'>{error.message}</Typography>}
              </Stack>
            );
          }}
        />
      </Box>
    );
  }
  return (
    <Box sx={containerProps?.sx}>
      {labelComponent}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Stack width='100%'>
              <Select
                id={`select-dropdown-${name}`}
                labelId={`select-dropdown-${name}-label`}
                label={labelComponent ? '' : label}
                placeholder={placeholder}
                size={size}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  if (onSelect) onSelect(e);
                }}
                fullWidth
                {...props}
              >
                {options.map((option) => (
                  <MenuItem disabled={option?.disabled} key={`${option.value}-${option.label}`} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
              {error && <Typography margin='4px 14px 0' sx={{ width: 'calc(100% - 4px)'}} fontSize={16} color='#d32f2f'>{error.message}</Typography>}
            </Stack>
          )
        }}
      />
    </Box>
  );
};

export default FormDropdownField;
