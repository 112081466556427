import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormTextField from '../../libs/ui/FormTextField';
import FormNumberField from '../../libs/ui/FormNumberField';
import FormDropdownField from '../../libs/ui/FormDropdownField';
import FormCheckBoxField from '../../libs/ui/FormCheckBoxField';
import FormSwitch from '../../libs/ui/FormSwitch';
import FormEditorField from '../../libs/ui/FormEditorField';
import { showSuccess } from '../../libs/utils/toasts';
import {
  IPages,
  PagesInput,
  useDropMenuPagesQuery,
  useFormsQuery,
  usePageByIdQuery,
  useUpdatePageMutation,
} from '../../graphql/generated/graphql';

export const PagesEditContainer = () => {
  const navigate = useNavigate();
  const { pageId } = useParams();

  const [formNames, setFormNames] = useState<any[]>([]);
  const [parentPages, setParentPages] = useState<any[]>([{ label: ' --- ', value: null }]);
  const [pageData, setPageData] = useState<IPages>();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Page Name is required'),
    url: Yup.string(),
    pageTitle: Yup.string().required('Page Title is required'),
    isNav: Yup.boolean(),
    isHome: Yup.boolean(),
    isShow: Yup.boolean(),
    dropMenu: Yup.boolean(),
    parentId: Yup.number().nullable(),
    order: Yup.number(),
    headLine: Yup.string().required('Header Text is required'),
    content: Yup.string(),
    sideContent: Yup.string(),
    formId: Yup.number().nullable(),
    isApplicationForm: Yup.bool(),
    isApplicationLogin: Yup.bool(),
    adminEmail: Yup.string(),
    fromEmail: Yup.string(),
    bccEmails: Yup.string(),
    loginFromEmail: Yup.string(),
    loginBccEmails: Yup.string(),
    loginSubjectEmail: Yup.string(),
    loginTitleEmail: Yup.string(),
    loginEmailContent: Yup.string(),
    loginEmailLongTermContent: Yup.string(),
    loginLinkEmail: Yup.string(),
    emailHeader: Yup.string(),
    emailBottom: Yup.string(),
    isFeedbackForm: Yup.bool(),
    feedbackFormFromEmail: Yup.string(),
    feedbackFormBccEmails: Yup.string(),
    feedbackFormSubjectEmail: Yup.string(),
    feedbackFormTitleEmail: Yup.string(),
    feedbackFormEmailContent: Yup.string(),
    feedbackFormLinkEmail: Yup.string(),
    isRequestEmail: Yup.bool(),
    requestFromEmail: Yup.string(),
    requestBccEmails: Yup.string(),
    requestSubjectEmail: Yup.string(),
    requestTitleEmail: Yup.string(),
    requestEmailContent: Yup.string(),
    isCloseAppEmail: Yup.bool(),
    closeAppFromEmail: Yup.string(),
    closeAppBccEmails: Yup.string(),
    closeAppSubjectEmail: Yup.string(),
    closeAppTitleEmail: Yup.string(),
    closeAppEmailContent: Yup.string(),
  });

  const methods = useForm({
    values: {
      name: pageData?.name ?? '',
      url: pageData?.url ?? '',
      pageTitle: pageData?.pageTitle ?? '',
      isNav: pageData?.isNav ?? false,
      isHome: pageData?.isHome ?? false,
      isShow: pageData?.isShow ?? true,
      dropMenu: pageData?.dropMenu ?? false,
      parentId: pageData?.parentId,
      order: pageData?.order ?? 0,
      content: pageData?.content ?? '',
      sideContent: pageData?.sideContent ?? '',
      headLine: pageData?.headLine ?? '',
      formId: pageData?.formId,
      isApplicationForm: pageData?.isApplicationForm ?? false,
      isApplicationLogin: pageData?.isApplicationLogin ?? false,
      adminEmail: pageData?.adminEmail ?? '',
      fromEmail: pageData?.fromEmail ?? '',
      bccEmails: pageData?.bccEmails ?? '',
      loginFromEmail: pageData?.loginFromEmail ?? '',
      loginBccEmails: pageData?.loginBccEmails ?? '',
      loginSubjectEmail: pageData?.loginSubjectEmail ?? '',
      loginTitleEmail: pageData?.loginTitleEmail ?? '',
      loginEmailContent: pageData?.loginEmailContent ?? '',
      loginEmailLongTermContent: pageData?.loginEmailLongTermContent ?? '',
      loginLinkEmail: pageData?.url ?? '',
      emailHeader: pageData?.emailHeader ?? '',
      emailBottom: pageData?.emailBottom ?? '',
      isFeedbackForm: pageData?.isFeedbackForm ?? false,
      feedbackFormFromEmail: pageData?.feedbackFormFromEmail ?? '',
      feedbackFormBccEmails: pageData?.feedbackFormBccEmails ?? '',
      feedbackFormSubjectEmail: pageData?.feedbackFormSubjectEmail ?? '',
      feedbackFormTitleEmail: pageData?.feedbackFormTitleEmail ?? '',
      feedbackFormEmailContent: pageData?.feedbackFormEmailContent ?? '',
      feedbackFormLinkEmail: pageData?.url ?? '',
      isRequestEmail: pageData?.isRequestEmail ?? false,
      requestFromEmail: pageData?.requestFromEmail ?? '',
      requestBccEmails: pageData?.requestBccEmails ?? '',
      requestSubjectEmail: pageData?.requestSubjectEmail ?? '',
      requestTitleEmail: pageData?.requestTitleEmail ?? '',
      requestEmailContent: pageData?.requestEmailContent ?? '',
      isCloseAppEmail: pageData?.isCloseAppEmail ?? false,
      closeAppFromEmail: pageData?.closeAppFromEmail ?? '',
      closeAppBccEmails: pageData?.closeAppBccEmails ?? '',
      closeAppSubjectEmail: pageData?.closeAppSubjectEmail ?? '',
      closeAppTitleEmail: pageData?.closeAppTitleEmail ?? '',
      closeAppEmailContent: pageData?.closeAppEmailContent ?? '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, watch, getValues } = methods;

  const { data } = usePageByIdQuery({
    variables: { pageId: Number(pageId) },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.Page.data) setPageData(data.Page.data);
  }, [data]);

  const { data: formsData } = useFormsQuery({
    variables: { page: 1, pageSize: 10000 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (formsData?.Forms.items) {
      const _forms = formsData.Forms.items.map((item) => ({label: item.name, value: item.id}));
      setFormNames([{label: ' --- ', value: null}, ..._forms]);
    }
  }, [formsData]);

  const { data: pageListData } = useDropMenuPagesQuery({
    variables: { page: 1, pageSize: 10000 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (pageListData?.Pages.items) {
      const _parentPages = pageListData.Pages.items.map((item) => {
        return { label: item.name, value: item.id.toString() };
      });
      setParentPages([{ label: ' --- ', value: null }, ...(_parentPages.filter(_page  => _page.value !== pageId))]);
    }
  }, [pageListData, pageId]);

  const [updatePageMutation] = useUpdatePageMutation();

  const updatePage = async (inputData: PagesInput) => {
    const { data } = await updatePageMutation({
      variables: { updatePagePage: inputData },
    });
    if (data?.UpdatePage.data) showSuccess('Page was updated successfully.');
  };

  const onSubmit = (data: FieldValue<any>) => {
    updatePage({ ...data, id: pageData?.id, loginLinkEmail: getValues('url'), feedbackFormLinkEmail: `${window.location.origin}/${getValues('url')}` });
  };

  return (
    <Grid container display='flex' justifyContent='center'>
      <Grid item xs={12}>
        <Typography variant='h5'>Edit Page</Typography>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={5} pr={2}>
          <FormTextField
            name='name'
            label='Page Name'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>Page Name</Typography>}
            size='small'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
            control={control}
          />
          <FormTextField
            name='pageTitle'
            label='Page Title'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>Page Title</Typography>}
            size='small'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
            control={control}
          />
          <FormTextField
            name='url'
            label='Page Url'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>Page Url</Typography>}
            size='small'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
            control={control}
          />
        </Grid>
        <Grid item xs={5} height={168} pl={2}>
          <Box display='flex' justifyContent='flex-start'>
            <FormSwitch
              name='isNav'
              label='Is Nav'
              labelComponent={<Typography width={80} fontSize={16} fontWeight={500} lineHeight='40px'>Is Nav</Typography>}
              containerProps={{ sx: { mt: 1.5, display: 'flex' } }}
              control={control}
            />
            <FormSwitch
              name='isHome'
              label='Is Home'
              labelComponent={<Typography width={80} fontSize={16} fontWeight={500} lineHeight='40px'>Is Home</Typography>}
              containerProps={{ sx: { mt: 1.5, ml: 4, display: 'flex' } }}
              control={control}
            />
            <FormSwitch
              name='isShow'
              label='Is Show'
              labelComponent={<Typography width={80} fontSize={16} fontWeight={500} lineHeight='40px'>Is Show</Typography>}
              containerProps={{ sx: { mt: 1.5, ml: 4, display: 'flex' } }}
              control={control}
            />
          </Box>
          <FormNumberField
            name='order'
            label='Order'
            labelComponent={<Typography width={130} fontSize={16} fontWeight={500} lineHeight='40px'>Order</Typography>}
            size='small'
            type='number'
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex' } }}
            control={control}
          />
          <FormDropdownField
            name='parentId'
            label='Parent Menu'
            labelComponent={<Typography minWidth={130} mt={2} mb={0.5} fontSize={16} fontWeight={500}>Parent Menu</Typography>}
            size='small'
            disabled={parentPages.length === 0}
            options={parentPages}
            sx={{ background: 'white' }}
            containerProps={{ sx: { mt: 2, display: 'flex' } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormTextField
            name='headLine'
            label='HeadLine'
            labelComponent={<Typography minWidth={180} fontSize={16} fontWeight={500} lineHeight='40px'>HeadLine</Typography>}
            size='small'
            rows={2}
            inputProps={{ sx: { backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormEditorField
            name='content'
            label='Content'
            labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Content</Typography>}
            rows={1}
            defaultValue={pageData?.content ?? ''}
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 1 } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormEditorField
            name='sideContent'
            label='Side Content'
            labelComponent={<Typography minWidth={130} fontSize={16} fontWeight={600} lineHeight='40px'>Side Content</Typography>}
            rows={1}
            defaultValue={pageData?.sideContent ?? ''}
            inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
            containerProps={{ sx: { mt: 1 } }}
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          {formNames.length > 0 &&
            <FormDropdownField
              name='formId'
              label='Form'
              labelComponent={<Typography minWidth={130} fontSize={16} lineHeight='40px'>Form</Typography>}
              size='small'
              options={formNames}
              sx={{ height: '40px', background: 'white' }}
              containerProps={{ sx: { mt: 2, display: 'flex', flexDirection: 'row' } }}
              control={control}
            />
          }
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {watch('formId') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={4}>
            <FormCheckBoxField
              name='isFeedbackForm'
              label='Is this the Request Feedback Form'
              size='small'
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>}
      {watch('isFeedbackForm') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormFromEmail'
              label='From Email'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormBccEmails'
              label='Bcc Emails'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormSubjectEmail'
              label='Subject'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Subject</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='feedbackFormTitleEmail'
              label='Title'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Title</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='feedbackFormEmailContent'
              label='Body'
              labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Body</Typography>}
              defaultValue={''}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
              menubar=''
              toolbar = "fontselect | fontsizeselect | bold italic underline | help |"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        {/* Text Field for the CMS to control the login route in the email sent to applicants */}
        {/* <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='loginLinkEmail'
              label='Login Link'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Login Link</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid> */}
      </>}
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormCheckBoxField
            name='isApplicationForm'
            label='Include Application Form'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {watch('isApplicationForm') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='adminEmail'
              label='Admin Email'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Admin Email</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='fromEmail'
              label='From Email'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='bccEmails'
              label='Bcc Emails'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              control={control}
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='emailHeader'
              label='Email Header'
              defaultValue={pageData?.emailHeader ?? ''}
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Email Header</Typography>}
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='emailBottom'
              label='Email Footer'
              defaultValue={pageData?.emailBottom ?? ''}
              labelComponent={<Typography sx={{ minWidth: '130px', fontSize: 16, fontWeight: 600, lineHeight: '40px' }}>Email Footer</Typography>}
              control={control}
              rows={1}
              inputProps={{ sx: { height: 40, backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 1 } }}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>}
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormCheckBoxField
            name='isApplicationLogin'
            label='Include Application Login'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
     
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormCheckBoxField
            name='isRequestEmail'
            label='Include Request Email'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {watch('isRequestEmail') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='requestFromEmail'
              label='From Email'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='requestBccEmails'
              label='Bcc Emails'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='requestSubjectEmail'
              label='Subject'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Subject</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='requestTitleEmail'
              label='Title'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Title</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='requestEmailContent'
              label='Body'
              labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Body</Typography>}
              defaultValue={pageData?.requestEmailContent ?? ''}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
              menubar=''
              toolbar = "fontselect | fontsizeselect | bold italic underline | help |"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>}
      <Grid item xs={12} container>
        <Grid item xs={1} />
        <Grid item xs={10} mt={4}>
          <FormCheckBoxField
            name='isCloseAppEmail'
            label='Include Close App Email'
            size='small'
            control={control}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      {watch('isCloseAppEmail') && <>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='closeAppFromEmail'
              label='From Email'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='closeAppBccEmails'
              label='Bcc Emails'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
              size='small'
              inputProps={{ sx: { backgroundColor: 'white' } }}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='closeAppSubjectEmail'
              label='Subject'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Subject</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormTextField
              name='closeAppTitleEmail'
              label='Title'
              size='small'
              labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Title</Typography>}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={1} />
          <Grid item xs={10} mt={2}>
            <FormEditorField
              name='closeAppEmailContent'
              label='Body'
              labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Body</Typography>}
              defaultValue={pageData?.closeAppEmailContent ?? ''}
              inputProps={{ sx: { backgroundColor: 'white' }}}
              containerProps={{ sx: { mt: 4 } }}
              control={control}
              menubar=''
              toolbar = "fontselect | fontsizeselect | bold italic underline | help |"
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>}
      <Grid item xs={8} pr={1} mt={4}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <Button variant='outlined' onClick={() => navigate(-1)}>Cancel</Button>
          <Button variant='contained' sx={{ ml: 2 }} onClick={handleSubmit(onSubmit)}>Save</Button>
        </Box>
      </Grid>
    </Grid>
  );
};


// {watch('isApplicationLogin') && <>
//   <Grid item xs={12} container>
//     <Grid item xs={1} />
//     <Grid item xs={10} mt={2}>
//       <FormTextField
//         name='loginFromEmail'
//         label='From Email'
//         labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>From Email</Typography>}
//         size='small'
//         inputProps={{ sx: { backgroundColor: 'white' }}}
//         containerProps={{ sx: { mt: 4 } }}
//         control={control}
//       />
//     </Grid>
//     <Grid item xs={1} />
//   </Grid>
//   <Grid item xs={12} container>
//     <Grid item xs={1} />
//     <Grid item xs={10} mt={2}>
//       <FormTextField
//         name='loginBccEmails'
//         label='Bcc Emails'
//         labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Bcc Emails</Typography>}
//         size='small'
//         inputProps={{ sx: { backgroundColor: 'white' } }}
//         containerProps={{ sx: { mt: 4 } }}
//         control={control}
//       />
//     </Grid>
//     <Grid item xs={1} />
//   </Grid>
//   <Grid item xs={12} container>
//     <Grid item xs={1} />
//     <Grid item xs={10} mt={2}>
//       <FormTextField
//         name='loginSubjectEmail'
//         label='Subject'
//         size='small'
//         labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Subject</Typography>}
//         inputProps={{ sx: { backgroundColor: 'white' }}}
//         containerProps={{ sx: { mt: 4 } }}
//         control={control}
//       />
//     </Grid>
//     <Grid item xs={1} />
//   </Grid>
//   <Grid item xs={12} container>
//     <Grid item xs={1} />
//     <Grid item xs={10} mt={2}>
//       <FormTextField
//         name='loginTitleEmail'
//         label='Title'
//         size='small'
//         labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Title</Typography>}
//         inputProps={{ sx: { backgroundColor: 'white' }}}
//         containerProps={{ sx: { mt: 4 } }}
//         control={control}
//       />
//     </Grid>
//     <Grid item xs={1} />
//   </Grid>
//   <Grid item xs={12} container>
//     <Grid item xs={1} />
//     <Grid item xs={10} mt={2}>
//       <FormEditorField
//         name='loginEmailContent'
//         label='Body'
//         labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Body</Typography>}
//         defaultValue={pageData?.loginEmailContent ?? ''}
//         inputProps={{ sx: { backgroundColor: 'white' }}}
//         containerProps={{ sx: { mt: 4 } }}
//         control={control}
//         menubar=''
//         toolbar = "fontselect | fontsizeselect | bold italic underline | help |"
//       />
//     </Grid>
//     <Grid item xs={1} />
//   </Grid>
//   <Grid item xs={12} container>
//     <Grid item xs={1} />
//     <Grid item xs={10} mt={2}>
//       <FormEditorField
//         name='loginEmailLongTermContent'
//         label='Body'
//         labelComponent={<Typography  fontSize={18} fontWeight={500} lineHeight={2}>Long term inquiry</Typography>}
//         defaultValue={pageData?.loginEmailLongTermContent ?? ''}
//         inputProps={{ sx: { backgroundColor: 'white' }}}
//         containerProps={{ sx: { mt: 4 } }}
//         control={control}
//         menubar=''
//         toolbar = "fontselect | fontsizeselect | bold italic underline | help |"
//       />
//     </Grid>
//     <Grid item xs={1} />
//   </Grid>
//   {/* Text Field for the CMS to control the login route in the email sent to applicants */}
//   {/* <Grid item xs={12} container>
//     <Grid item xs={1} />
//     <Grid item xs={10} mt={2}>
//       <FormTextField
//         name='loginLinkEmail'
//         label='Login Link'
//         size='small'
//         labelComponent={<Typography fontSize={18} fontWeight={500} lineHeight={2}>Login Link</Typography>}
//         inputProps={{ sx: { backgroundColor: 'white' }}}
//         containerProps={{ sx: { mt: 4 } }}
//         control={control}
//       />
//     </Grid>
//     <Grid item xs={1} />
//   </Grid> */}
// </>}