import { useMemo, useState } from 'react';
import { Box, Table, TableBody, TableContainer, Typography, TableHead, Divider, TableSortLabel } from '@mui/material';
import { StyledTableCell as TableCell, StyledTableRow } from '../../libs/ui/StyledComponents';
import { ResourceSortField, SortDirectionField, useResourcesListQuery } from '../../graphql/generated/graphql';

const InventoryReports = () => {
  //ResourceSortField
  const [orderBy, setOrderBy] = useState<ResourceSortField>(ResourceSortField.Title);
  const [orderDirection, setOrderDirection] = useState<SortDirectionField>(SortDirectionField.Asc);
  const { data } = useResourcesListQuery({ fetchPolicy: 'network-only', variables: {
    sort: orderBy, sortDirection: orderDirection
  } });

  const summary = useMemo(() => {
    if (data?.Resources?.items && data.Resources.items.length > 0) {
      return data.Resources.items
    }
    return []
  }, [data])

  const handleRequestSort = (column: ResourceSortField) => {
      const isAsc = orderBy === column && orderDirection === SortDirectionField.Asc;
      const newDirection = isAsc ? SortDirectionField.Desc : SortDirectionField.Asc;
      
      setOrderBy(column);
      setOrderDirection(newDirection);
      
  };

  return (
    <Box mt={1}>
      <Typography variant='h6' fontWeight={600}>Inventory</Typography>
      <TableContainer>
        <Table aria-labelledby='tableTitle' size='small'>
          <TableHead>
            <StyledTableRow>
              <TableCell sx={{ width: '150px' }}>
                <TableSortLabel
                  active={orderBy === ResourceSortField.Sku}
                  direction={orderBy === ResourceSortField.Sku ? orderDirection.toLowerCase() as "asc" | "desc" : "asc"}
                  onClick={() => handleRequestSort(ResourceSortField.Sku)}
                >
                   SKU
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                    active={orderBy === ResourceSortField.Title}
                    direction={orderBy === ResourceSortField.Title ? orderDirection.toLowerCase() as "asc" | "desc" : "asc"}
                    onClick={() => handleRequestSort(ResourceSortField.Title)}
                  >
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ width: '150px', paddingLeft: '12px!important' }}>Count</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {summary.map(row => (
              <StyledTableRow hover role='checkbox' tabIndex={-1}>
                <TableCell align='left'>{row.sku}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.title}</TableCell>
                <TableCell align='left' sx={{ paddingLeft: '12px!important' }}>{row.inventory}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ borderWidth: '1px', marginTop: '12px' }} />
    </Box>
  );
};

export default InventoryReports;
