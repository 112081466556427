import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { FieldValue, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormTextField from "../../libs/ui/FormTextField";
import {
  IResource,
  useResourcesListQuery,
  useExclusionGroupByIdQuery,
  ExclusionGroupInput,
  ExclusionGroupUpdateInput,
  UpdateExclusionGroupDocument,
  ExclusionGroupsDocument,
  CreateExclusionGroupDocument,
} from "../../graphql/generated/graphql";
import FormDropdownField, {
  DropDownItem,
} from "../../libs/ui/FormDropdownField";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import FormEditorField from "../../libs/ui/FormEditorField";

export const ExclusionGroupEditForm = () => {
  const navigate = useNavigate();
  const [exclusionId, setExclusionId] = useState<string | undefined>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const params = useParams() as { exclusionId: string };
  const [resourceItems, setResourceItems] = useState<DropDownItem[]>([
    { label: "Loading...", value: "Loading..." },
  ]);
  const [exclusionItem, setExclusionItem] = useState<any>();

  const { data: resourcesData } = useResourcesListQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (params.exclusionId) {
      setExclusionId(params.exclusionId);
      setIsEdit(true);
    }
  }, [params]);

  const [updateExclusionGroupMutation] = useMutation(
    UpdateExclusionGroupDocument,
    {
      refetchQueries: [
        {
          query: ExclusionGroupsDocument,
          fetchPolicy: "network-only",
        },
      ],
    }
  );

  const [createExclusionGroupQuery] = useMutation(
    CreateExclusionGroupDocument,
    {
      refetchQueries: [
        {
          query: ExclusionGroupsDocument,
          fetchPolicy: "network-only",
        },
      ],
    }
  );

  const { data: exclusionGroupData, loading } = useExclusionGroupByIdQuery({
    variables: { exclusionGroupId: Number(exclusionId) },
    fetchPolicy: "network-only",
    skip: !exclusionId,
  });

  useEffect(() => {
    setPageLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (exclusionGroupData?.ExclusionGroup.data) {
      setExclusionItem(exclusionGroupData!.ExclusionGroup!.data);
    }
  }, [exclusionGroupData]);

  useEffect(() => {
    if (resourcesData?.Resources.items) {
      const _forms = [...resourcesData.Resources.items]
        .sort((a: any, b: any) => {
          return a?.title?.toLowerCase() > b?.title?.toLowerCase()
            ? 1
            : b?.title?.toLowerCase() > a?.title?.toLowerCase()
            ? -1
            : 0;
        })
        .map((item) => ({
          label: item.title,
          value: item.id,
        }));
      setResourceItems([..._forms]);
    }
  }, [resourcesData]);

  const updateExclusionGroup = async (
    inputData: ExclusionGroupInput | ExclusionGroupUpdateInput
  ) => {
    setPageLoading(true)
    const { data } = await updateExclusionGroupMutation({
      variables: {
        exclusionGroup: {
          id: +exclusionId!,
          ...inputData,
        },
      },
    });
    if (data?.UpdateExclusionGroup?.data) {
      navigate('/app/exclusions');
    }
  };

  const createExclusionGroup = async (inputData: ExclusionGroupInput) => {
    setPageLoading(true)
    const { data } = await createExclusionGroupQuery({
      variables: { exclusionGroup: inputData },
    });
    if (data?.CreateExclusionGroup?.data) {
      navigate('/app/exclusions');
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    // endDateDays: Yup.number().required("Days Till End Date is required"),
    isActive: Yup.bool(),
    resourceIds: Yup.array().nullable(),
    loginFromEmail: Yup.string().nullable(),
    loginBccEmails: Yup.string().nullable(),
    loginSubjectEmail: Yup.string().nullable(),
    loginTitleEmail: Yup.string().nullable(),
    loginEmailContent: Yup.string().nullable(),
    //loginEmailLongTermContent: Yup.string().nullable(),
  });

  const methods = useForm({
    values: {
      name: exclusionItem?.name || "",
      description: exclusionItem?.description ?? "",
      //endDateDays: exclusionItem?.endDateDays ?? 365,
      isActive: exclusionItem?.isActive ?? true,
      resourceIds: exclusionGroupData?.ExclusionGroup?.data?.resources
        ? exclusionGroupData?.ExclusionGroup?.data?.resources?.map(
            (_resource: IResource) => _resource.id
          )
        : [],
      loginFromEmail: exclusionItem?.loginFromEmail,
      loginSubjectEmail: exclusionItem?.loginSubjectEmail,
      loginTitleEmail: exclusionItem?.loginTitleEmail,
      loginEmailContent: exclusionItem?.loginEmailContent,
      //loginEmailLongTermContent: exclusionItem?.loginEmailLongTermContent,
      loginBccEmails: exclusionItem?.loginBccEmails,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control } = methods;

  const onSubmit = async (data: FieldValue<any>) => {
    if (isEdit) {
      await updateExclusionGroup(data);
    } else {
      await createExclusionGroup(data);
    }
  };

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={pageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box px={3}>
        <Typography
          variant="h5"
          fontWeight={600}
          id="delete-category-modal-description"
          sx={{ mb: 3 }}
        >
          {isEdit ? "Edit Exclusion Group" : "Create new Exclusion Group"}
        </Typography>
        <Box bgcolor="white" borderRadius={2} mt={3} p={2} boxShadow={3}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormTextField
                name="name"
                label="Name"
                size="small"
                labelComponent={
                  <Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>
                    Title{" "}
                    <span style={{ fontWeight: 400, color: "#f00" }}>*</span>
                  </Typography>
                }
                control={control}
                inputProps={{ sx: { backgroundColor: "white" } }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormDropdownField
                name="resourceIds"
                fullWidth
                label="Excluded Resources"
                size="small"
                multiple
                options={resourceItems}
                labelComponent={
                  <Typography
                    sx={{ minWidth: 130, fontSize: 16, lineHeight: "40px" }}
                  >
                    Excluded Resources
                  </Typography>
                }
                control={control}
                sx={{
                  background: "white",
                  height: "40px",
                }}
                containerProps={{
                  sx: {
                    mt: 1,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <FormTextField
                name="endDateDays"
                label="Days Till End Date"
                size="small"
                type="number"
                labelComponent={
                  <Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>
                    Days Till Due Date{" "}
                    <span style={{ fontWeight: 400, color: "#f00" }}>*</span>
                  </Typography>
                }
                control={control}
                inputProps={{ sx: { backgroundColor: "white" } }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <FormTextField
                name="description"
                label="Description"
                size="small"
                labelComponent={
                  <Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>
                    Description{" "}
                    <span style={{ fontWeight: 400, color: "#f00" }}>*</span>
                  </Typography>
                }
                control={control}
                inputProps={{ sx: { backgroundColor: "white" } }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box bgcolor="white" borderRadius={2} mt={3} p={2} boxShadow={3}>
          <Grid container>
            <Grid item xs={12}>
              <FormTextField
                name="loginFromEmail"
                label="From Email"
                size="small"
                labelComponent={
                  <Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>
                    From Email{" "}
                    <span style={{ fontWeight: 400, color: "#f00" }}>*</span>
                  </Typography>
                }
                control={control}
                inputProps={{ sx: { backgroundColor: "white" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="loginBccEmails"
                label="Bcc Emails"
                size="small"
                labelComponent={
                  <Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>
                    Bcc Emails{" "}
                    <span style={{ fontWeight: 400, color: "#f00" }}>*</span>
                  </Typography>
                }
                control={control}
                inputProps={{ sx: { backgroundColor: "white" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="loginSubjectEmail"
                label="Subject"
                size="small"
                labelComponent={
                  <Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>
                    Subject{" "}
                    <span style={{ fontWeight: 400, color: "#f00" }}>*</span>
                  </Typography>
                }
                control={control}
                inputProps={{ sx: { backgroundColor: "white" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="loginTitleEmail"
                label="Title"
                size="small"
                labelComponent={
                  <Typography fontSize={18} fontWeight={500} mt={2} mb={0.5}>
                    Title{" "}
                    <span style={{ fontWeight: 400, color: "#f00" }}>*</span>
                  </Typography>
                }
                control={control}
                inputProps={{ sx: { backgroundColor: "white" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormEditorField
                name="loginEmailContent"
                label="Body"
                labelComponent={
                  <Typography
                    minWidth={130}
                    fontSize={16}
                    fontWeight={600}
                    lineHeight="40px"
                  >
                    Body
                  </Typography>
                }
                rows={1}
                defaultValue={exclusionItem?.loginEmailContent ?? ""}
                inputProps={{ sx: { height: 40, backgroundColor: "white" } }}
                containerProps={{ sx: { mt: 1 } }}
                control={control}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormEditorField
                name="loginEmailLongTermContent"
                label="Long term inquiry"
                labelComponent={
                  <Typography
                    minWidth={130}
                    fontSize={16}
                    fontWeight={600}
                    lineHeight="40px"
                  >
                    Long term inquiry
                  </Typography>
                }
                rows={1}
                defaultValue={exclusionItem?.loginEmailLongTermContent ?? ""}
                inputProps={{ sx: { height: 40, backgroundColor: "white" } }}
                containerProps={{ sx: { mt: 1 } }}
                control={control}
              />
            </Grid> */}
          </Grid>
        </Box>
        <Box width="100%" mt={2} display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={()=> navigate('/app/exclusions')}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};
